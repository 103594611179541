import axios from 'axios';
import config from '../config';
import { showError } from '../utils';
import { store } from '../store';

window.audioContext = new (window.AudioContext || window.webkitAudioContext)();

export function fetchSounds() {
  const newMsgUrl = '/new_message.m4a';
  axios
    .get(newMsgUrl, {
      responseType: 'arraybuffer',
    })
    .then((res) => {
      const arrayBuffer = res.data;
      window.audioContext
        .decodeAudioData(arrayBuffer)
        .then((audioBuffer) => {
          window.newMsgAudioBuffer = audioBuffer;
        })
        .catch((err) => {
          console.log(err);
        });
      return null;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function fetchTranscriptionConsults(
  authToken,
  filters,
  selectedSponsorId,
  selectedDoctorId,
  searchText,
  consultationIdKey,
  fromDate,
  toDate
) {
  const headers = {
    authorization: authToken,
  };

  const { partnerSponsorId } = store.getState().user;

  let baseUrl;

  if (partnerSponsorId) {
    baseUrl = `${config.apiBaseUrl}/digitisations/requests?sponsorId=${partnerSponsorId}`;
  } else {
    baseUrl = `${config.apiBaseUrl}/digitisations/requests?`;
  }

  //const baseUrl = `http://localhost:5001/digitisations/requests?`;

  const { selectedSoruce } = store.getState().filters;
  let params = { v: config.dashboardVersion };

  if (searchText) {
    params['searchString'] = searchText;
  }
  if (consultationIdKey) {
    params['consultationId'] = consultationIdKey;
  }
  if (selectedSponsorId && !partnerSponsorId) {
    params['sponsorId'] = selectedSponsorId;
  }
  if (selectedDoctorId) {
    params['doctorId'] = selectedDoctorId;
  }
  if (selectedSoruce) {
    params['sourceType'] = selectedSoruce;
  }
  if (fromDate) {
    params['fromDate'] = fromDate;
  }
  if (toDate) {
    params['toDate'] = toDate;
  }

  if (filters) {
    filters.forEach((filter) => {
      if (filter.isSelected) {
        switch (filter.name) {
          case 'Digitized': {
            console.log({ filters }, 'inside digitized');
            params['status'] = 'complete';
            break;
          }
          case 'Pending': {
            console.log({ filters }, 'inside digitized');
            params['status'] = 'pending';
            break;
          }
          case 'Invalid': {
            console.log({ filters }, 'inside Invalid');
            params['invalid'] = 1;
            break;
          }
          case 'Suspicious Users': {
            console.log({ filters }, 'inside isSuspicious');
            params['isSuspicious'] = 1;
            break;
          }
          default: {
            break;
          }
        }
      }
    });

    // if all filter selected ignore rest all filters
    if (filters[0].isSelected) {
      params = {};
    }
  }

  return axios
    .get(baseUrl, {
      headers,
      params,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return { responseBody, responseURL: res.request.responseURL };
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchAllSponsors(authToken) {
  const baseUrl = `${config.apiBaseUrl}/new-auth/doctor/get-sponsors`;
  const headers = {
    authorization: authToken,
  };
  console.log(headers, 'headers for fetchAllSponsors');
  return axios
    .get(baseUrl, {headers})
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchPendingCallbacks(obj, searchText, sponsorId, startDate, endDate) {
  const baseUrl = `${config.apiBaseUrl}/opd/get-pending-callbacks/new`;
  console.log();
  const headers = {
    authorization: obj.authToken,
  };
  let params = {};
  if(searchText) {
    params['userName'] = searchText;
  }
  if(sponsorId) {
    params['sponsorId'] = sponsorId;
  }
  if(startDate) {
    params['startDate'] = startDate;
  }
  if(endDate) {
    params['endDate'] = endDate;
  }
  if(obj.verticalId) {
    params['verticalId'] = obj.verticalId;
  }
  console.log(headers, 'headers for fetchPendingCallbacks');
  return axios
    .get(baseUrl, {headers, params})
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function markCallbackComplete(authToken, connectionRequestId, consultationId) {
  const baseUrl = `${config.apiBaseUrl}/opd/mark-callback-status-as-completed`;
  const headers = {
    authorization: authToken,
  };
  const body = {
    connectionRequestId,
    consultationId,
  };
  console.log(headers, 'headers for markCallbackComplete');
  return axios
    .post(baseUrl, body, {headers})
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        console.log(responseBody, 'responseBody for markCallbackComplete');
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchConsults(
  authToken,
  filters,
  selectedSponsorId,
  searchText,
  consultationIdKey,
  page,
  startDate, 
  endDate,
) {
  const headers = {
    authorization: authToken,
  };

  const { partnerSponsorId } = store.getState().user;

  let baseUrl;

  console.log(consultationIdKey);

  if (partnerSponsorId) {
    baseUrl = `${config.apiBaseUrl}/new-doctor/get-all-recent-cases?sponsorId=${partnerSponsorId}`;
  } else {
    baseUrl = `${config.apiBaseUrl}/new-doctor/get-all-recent-cases`;
  }

  let params = {};

  if (searchText) {
    params['name'] = searchText;
  }
  if (consultationIdKey) {
    params['consultationId'] = consultationIdKey;
  }
  if (selectedSponsorId && !partnerSponsorId) {
    params['sponsorId'] = selectedSponsorId;
  }
  if(page) {
    params['page'] = page;
  }
  if(startDate) {
    params['startDate'] = startDate;
  }
  if(endDate) {
    params['endDate'] = endDate;
  }
  if (filters) {
    filters.forEach((filter) => {
      if (filter.isSelected) {
        switch (filter.name) {
          case 'Unattented': {
            params['unattented'] = true;
            break;
          }
          case 'Ongoing': {
            params['closed'] = false;
            break;
          }
          case 'Corona Cases': {
            params['coronaUsersOnly'] = true;
            break;
          }
          // case 'Followups': {
          //   params['followupsOnly'] = true;
          //   break;
          // }
          case 'Completed': {
            params['closed'] = true;
            break;
          }
          case 'Scheduled Sessions': {
            params['callScheduledConsultsOnly'] = true;
            break;
          }
          case 'Connections': {
            params['connections'] = true;
            break;
          }
          case 'Unread Messages': {
            params['unreadChats'] = true;
            break;
          }
          case 'Live Cases': {
            params['liveCases'] = true;
            console.log(filter);
            params['verticalId'] = filter.verticalId;
            break;
          }
          case 'Open Cases': {
            params['openCases'] = true;
            break;
          }
          case 'Past Cases': {
            params['pastCases'] = true;
            break;
          }
          case 'Unreplied': {
            params['unreplied'] = true;
            console.log(filter);
            params['verticalId'] = filter.verticalId;
            break;
          }
          default: {
            break;
          }
        }
      }
    });

    // if all filter selected ignore rest all filters
    // if (filters[0].isSelected) {
    //   params = {};
    // }
  }

  console.log({ baseUrl, headers, params }, 'baseUrl for fetchConsults');

  return axios
    .get(baseUrl, {
      headers,
      params,
    })
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message === 'success') {
        return { responseBody, responseURL: res.request.responseURL };
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchConsultsOnPagination(authToken, page, url) {
  const headers = {
    authorization: authToken,
  };

  console.log({ headers, page, url }, 'fetchConsultsOnPagination');

  return axios
    .get(url, {
      headers,
      params: {
        page,
      },
    })
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function recommendSpecialist(
  authToken,
  userId,
  relativeId,
  vertical,
  type,
  consultationId
) {
  const body = { userId, vertical, type, consultationId, relativeId };

  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/opd/suggest`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return {
          body: responseBody,
        };
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function uploadImageAttachment(
  file,
  doctorId,
  consultationId,
  userId,
  onUploadProgress,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  const formData = new FormData();
  formData.append('file', file);
  formData.append('consultationId', consultationId);
  formData.append('doctorId', doctorId);
  formData.append('userId', userId);
  formData.append('preview', file);

  return axios
    .post(`${config.apiBaseUrl}/new-doctor/uploads/file`, formData, {
      headers,
      onUploadProgress,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function uploadDietAttachment(
  file,
  doctorId,
  consultationId,
  userId,
  onUploadProgress,
  authToken
) {
  const headers = {
    authorization: authToken,
  };

  const formData = new FormData();
  formData.append('file', file);
  formData.append('consultationId', consultationId);
  formData.append('doctorId', doctorId);
  formData.append('userId', userId);

  const params = {
    isDietPlan: 1,
  };

  return axios
    .post(`${config.apiBaseUrl}/new-doctor/uploads/file`, formData, {
      headers,
      onUploadProgress,
      params,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchReasons(value){
  const {information} = value;
  const authToken = information.authToken;
  const headers = {
      authorization: authToken,
  }
  const params = {
      userId: information.activeConsult.user.userId,
      relativeId: information.consultationInfo.relativeId ? information.consultationInfo.relativeId : null,
      consultationId: information.activeConsult.consultationId,
  };
  console.log(params, headers, 'fetchReasons');
  return axios.get(`${config.apiBaseUrl}/emergency-contact/get-reasons`,{
    params,
    headers,
  })
  .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
          return responseBody;
      } else {
          const err = new Error('Invalid response');
          err.data = responseBody;
          throw err;
      }
  })
  .catch((err) => {
      showError(err);
      throw err;
  });
}

export function fetchContactDetails(value) {
  console.log(value, 'fetchContactDetails');
  const {information} = value;
  const authToken = information.authToken;
  const headers = {
      authorization: authToken,
  }
  const params = {
      consultationId: information.activeConsult.consultationId,
      verticalId: information.consultationInfo.doctorVertical,
  }
  console.log(params, headers, 'fetchContactDetails');
  return axios.get(`${config.apiBaseUrl}/emergency-contact/get-info-for-doctor`,{
    params,
    headers,
  })
  .then((res) => {
      const responseBody = res.data;
      console.log(responseBody, 'responseBody');
      return responseBody;
  })
  .catch((err) => {
      showError(err);
      throw err;
  });
};

export function fetchReportedReasons(data) {
  console.log(data, 'fetchReportedReasons');
  const {information} = data;
  const authToken = information.authToken;
  const headers = {
      authorization: authToken,
  }
  const params = {
      consultationId: information.activeConsult.consultationId,
      userId: information.activeConsult.user.userId,
      relativeId: information.consultationInfo.relativeId ? information.consultationInfo.relativeId : null,
  }
  console.log(params, headers, 'fetchReportedReasons');
  return axios.get(`${config.apiBaseUrl}/emergency-contact/get-reported-reasons`,{
    params,
    headers,
  })
  .then((res) => {
      const responseBody = res.data;
      console.log(responseBody, 'responseBody');
      return responseBody;
  })
  .catch((err) => {
      showError(err);
      throw err;
  });
}

export function postReportedReasons(reasons, data) {
  const {information} = data;
  const authToken = information.authToken;
  const headers = {
      authorization: authToken,
  };
  const body = {
      consultationId: information.activeConsult.consultationId,
      reasonsArray: reasons, 
  };
  console.log(body, headers, 'postReportedReasons');
  return axios.post(`${config.apiBaseUrl}/emergency-contact/report-reasons`, body,{
    headers
  })
  .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
          return responseBody;
      } else {
          const err = new Error('Invalid response');
          err.data = responseBody;
          throw err;
      }
  })
  .catch((err) => {
      showError(err);
      throw err;
  });
};

export function postReasonsAndCall(reasons, data) {
  const {information} = data;
  const authToken = information.authToken;
  const headers = {
      authorization: authToken,
  };
  const body = {
      userId: information.activeConsult.user.userId,
      relativeId: information.consultationInfo.relativeId ? information.consultationInfo.relativeId : null,
      reasonsArray: reasons,
      consultationId: information.activeConsult.consultationId,
  };
  console.log(body, headers, 'postReasonsAndCall');
  return axios.post(`${config.apiBaseUrl}/emergency-contact/initiate-call`,body,{headers})
  .then((res) => {
      const responseBody = res.data;
      console.log(responseBody, 'postReasonsAndCall');
      if (responseBody.message === 'success') {
          return responseBody;
      } else {
          const err = new Error('Invalid response');
          err.data = responseBody;
          throw err;
      }
  })
  .catch((err) => {
      showError(err);
      throw err;
  });
}

export function videoCallUser(authToken, doctorId, consultationId) {
  const headers = {
    authorization: authToken,
  };

  console.log({ headers }, 'videoCallUser');

  return axios
    .post(
      `${config.apiBaseUrl}/new-doctor/consultations/video/callPatient/${doctorId}?consultationId=${consultationId}`,
      {},
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody.callInfo;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function stopVideoCall(authToken, doctorId, sessionId) {
  const headers = {
    authorization: authToken,
  };

  console.log({ headers }, 'videoCallUser');

  return axios
    .post(
      `${config.apiBaseUrl}/new-doctor/consultations/video/endCall/${doctorId}?sessionId=${sessionId}`,
      {},
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function closeConsultation(
  authToken,
  consultationId,
  followDateTime,
  followupNotes,
  endReason
) {
  const headers = {
    authorization: authToken,
  };

  console.log(
    { headers, consultationId, followDateTime, followupNotes, endReason },
    'closeConsultation'
  );

  return axios
    .post(
      `${config.apiBaseUrl}/new-doctor/close-consult`,
      {
        consultationId,
        followDateTime,
        followupNotes,
        endReason,
      },
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function voiceCallUser(authToken, consultationId) {
  const headers = {
    authorization: authToken,
  };

  console.log({ headers }, 'videoCallUser');

  return axios
    .post(
      `${config.apiBaseUrl}/new-doctor/consultations/voice/call`,
      {
        consultationId,
      },
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getConsultationInfo(authToken, consultationId, doctorId) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/opd/consultation/${consultationId}/doctor/summary/${doctorId}`,
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

let sourceAbortController = null;
export function getDigitiseRequest(authToken, digitisationRequestId) {
  if (sourceAbortController) {
    sourceAbortController.abort();
  }
  sourceAbortController = new AbortController();
  const headers = {
    authorization: authToken,
  };


  return axios
    .get(
      `${config.apiBaseUrl}/digitisations/requests/source/${digitisationRequestId}?v=${config.dashboardVersion}`,
      {
        headers,
        // signal: sourceAbortController.signal
      }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      if (!axios.isCancel(err)) {
        showError(err);
        throw err;
      }
    });
}

export function offlineConsultationStatus(
  authToken,
  consultationId,
  status,
  requestId
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/new-doctor/consultations/${consultationId}/resolve`,
      {
        status,
        requestId,
      },
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getPastTreatments(authToken, consultationId) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/new-doctor/consultations/${consultationId}/pastTreatments`,
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function markDuplicateRequest(
  authToken,
  requestId,
  duplicateOfRequestId
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/digitisations/requests/mark-duplicate`,
      { requestId, duplicateOfRequestId },
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getPastDigitization(authToken, digitizationId) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/digitisations/requests/past-digitisations/${digitizationId}`,
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function removeUploadedPrescription(authToken, consultationId, fileId) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/new-doctor/transcription/remove-file`,
      {
        consultationId,
        fileId,
      },
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function updateUserDetails(authToken, userId, dob, gender) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/new-doctor/update-user-details`,
      {
        userId,
        dob,
        gender,
      },
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

let prescriptionAbortController = null;
export function getPrescriptionFiles(authToken, digitisationRequestId) {
  if (prescriptionAbortController) {
    prescriptionAbortController.abort();
  }
  prescriptionAbortController = new AbortController();
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/digitisations/requests/documents/get/${digitisationRequestId}?v=${config.dashboardVersion}`,
      {
        headers,
        // signal: prescriptionAbortController.signal
      }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      if (!axios.isCancel(err)) {
        showError(err);
        throw err;
      }
    });
}


export function markInvalidPrescription(
  authToken,
  consultationId,
  invalidReason
) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/new-doctor/transcription/mark-invalid`,
      {
        consultationId,
        invalidReason,
      },
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function doctorInitiateVideoCall(authToken, doctorId, consultationId) {
  const headers = {
    authorization: authToken,
  };
  const body = {
    doctorId,
    consultationId,
  };
  console.log({ headers }, 'doctorInitiateVideoCall');

  return axios
    .post(`${config.apiBaseUrl}/video-call/doctor-initiate`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody.roomDetails;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function docEndCall(authToken, body) {
  const headers = {
    authorization: authToken,
  };

  console.log({ headers }, 'docEndCall');

  return axios
    .post(`${config.apiBaseUrl}/video-call/doc-end-call`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function videoSessionInfo(authToken, sessionId, consultationId) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.apiBaseUrl}/video-call/video-session-doc-info?sessionId=${sessionId}&consultationId=${consultationId}`,
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function submitVideoFeedback(authToken, body) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/video-call/doctor-feedback`, body, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      const { message } = responseBody;
      if (message === 'success') {
        return responseBody;
      }
      const err = new Error('Invalid response');
      err.data = responseBody;
      throw err;
    })
    .catch((err) => {
      throw err;
    });
}

export function getUserRelative(userId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/new-doctor/users/${userId}/relatives`, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function updateConsultationRelative(authToken, body) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/new-doctor/update-consultation-relative`,
      body,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      const { message } = responseBody;
      if (message === 'success') {
        return responseBody;
      }
      const err = new Error('Invalid response');
      err.data = responseBody;
      throw err;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addConsultationRelative(authToken, body) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/new-doctor/add-relative`, body, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      const { message } = responseBody;
      if (message === 'success') {
        return responseBody;
      }
      const err = new Error('Invalid response');
      err.data = responseBody;
      throw err;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function bookConsultation(authToken, body) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/new-doctor/book-consultation-relative`, body, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      const { message } = responseBody;
      if (message === 'success') {
        return responseBody;
      }
      const err = new Error('Invalid response');
      err.data = responseBody;
      throw err;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function scheduleCall(authToken, body) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/consultations/schedule-a-call`, body, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      const { message } = responseBody;
      if (message === 'success') {
        return responseBody;
      }
      const err = new Error('Invalid response');
      err.data = responseBody;
      throw err;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function cancelScheduleCall(consultationId, authToken) {
  const headers = {
    authorization: authToken,
  };

  const body = {
    consultationId,
    cancelledBy: 'specialist',
  };
  return axios
    .post(`${config.apiBaseUrl}/consultations/cancel-a-scheduled-call`, body, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchScheduleCardDetail(scheduleId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/consultations/schedule-status/${scheduleId}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export function closeConsultationNew(
  authToken,
  consultationId,
  remark,
  closedType,
  consultationNote,
  othersRemark
) {
  const headers = {
    authorization: authToken,
  };

  console.log(
    {
      headers,
      consultationId,
      remark,
      othersRemark,
    },
    'closeConsultationNew'
  );

  return axios
    .post(
      `${config.apiBaseUrl}/new-doctor/close-consult-new`,
      {
        consultationId,
        selectedReason: remark,
        consultationNote,
        closedType,
        remark: othersRemark,
      },
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      }
      const err = new Error('Invalid response');
      err.data = responseBody;
      throw err;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchConsultationEndReasons(authToken, type) {
  const headers = {
    authorization: authToken,
  };
  console.log(
    {
      authToken,
    },
    'fetchConsultationEndReasons'
  );
  return axios
    .get(
      `${config.apiBaseUrl}/new-doctor/get-cancel-consultation-reasons?type=${type}`,
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function submitDiagnosisWhileClosingConsultation(authToken, body) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/pristyn-care/add-post-consultation-data-new-visit`,
      body,
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getUserDetailsUsingConsultId(authToken, userId) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/pristyn-care/${userId}/get-user-details`, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}
export function getAllDigitisationDetail(
  statusValue,
  doctorId,
  source,
  sponsorId,
  searchString,
  sd,
  ed,
  pageSize,
  page,
  authToken
) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(
      `${config.apiBaseUrl}/digitisations/get-filtered-digitisation-details?status=${statusValue?statusValue:'pending'}&doctorId=${doctorId}&searchString=${searchString}&source=${source}&sponsor=${sponsorId}&sd=${sd}&ed=${ed}&pageSize=${pageSize}&page=${page}`,
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getAllDoctorDigitiser(authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(`${config.apiBaseUrl}/digitisations/get-all-doctor-digitiser`, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}
export function bulkAssign(digitisationIds, assignTo, authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .post(
      `${config.apiBaseUrl}/digitisations/transfer-digitisation-cases`,
      {
        digitisationIds,
        assignTo,
      },
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getAllAdminDoctor(authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(`${config.apiBaseUrl}/digitisations/get-all-privileges-doctor`, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function removeDoctorAdmin(doctorId, authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .put(
      `${config.apiBaseUrl}/digitisations/remove-doctor-admin`,
      { doctorId },
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}
export function getAllDoctor(input, authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(`${config.apiBaseUrl}/digitisations/get-all-doctor-list?q=${input}`, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody.result;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addDoctorAdmin(doctorId, authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .post(
      `${config.apiBaseUrl}/digitisations/add-doctor-admin`,
      { doctorId },
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchAllSponsor(input, authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(
      `${config.apiBaseUrl}/digitisations/fetch-all-sponsor-list?q=${input}`,
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody.result;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchAllTrascriberDoctorList(authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(`${config.apiBaseUrl}/digitisations/fetch-transcriber-doctor-list`, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function removeTranscriberAccount(doctorId, authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .put(
      `${config.apiBaseUrl}/digitisations/remove-transcriber-doctor`,
      { doctorId },
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function addTranscriberAccount(
  doctorId,
  uploadedLabs,
  uploadedMeds,
  offlineConsultations,
  reimbursement,
  authToken
) {
  const body = {
    doctorId,
    uploadedLabs,
    uploadedMeds,
    offlineConsultations,
    reimbursement,
  };
  const headers = {
    authorization: authToken,
  };
  return axios
    .post(`${config.apiBaseUrl}/digitisations/add-transcriber-doctor`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchAllDoctorForTranscriber(input, authToken) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(
      `${config.apiBaseUrl}/digitisations/get-doctor-list-trascriber?q=${input}`,
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody.result;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function editTrnascriberDocAccount(
  doctorId,
  uploadedLabs,
  uploadedMeds,
  offlineConsultations,
  reimbursement,
  authToken
) {
  const body = {
    doctorId,
    uploadedLabs,
    uploadedMeds,
    offlineConsultations,
    reimbursement,
  };
  const headers = {
    authorization: authToken,
  };
  return axios
    .put(`${config.apiBaseUrl}/digitisations/edit-trascriber-doctor`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export const downloadDigitisationCsvFile = (params) => {
  const {
    page,
    pageSize,
    sponsor,
    searchString,
    startDate,
    endDate,
    status,
    doctorId,
    source,
  } = params;

  const url = `${config.apiBaseUrl}/digitisations/download-digitisation-mis-report?page=${page}&pageSize=${pageSize}&sponsor=${sponsor}&searchString=${searchString}&doctorId=${doctorId}&status=${status}&source=${source}&reqReceivedStartTimestamp=${startDate}&reqReceivedEndTimestamp=${endDate}`;
  window.open(url);
  return Promise.resolve();
};

export const getPolicyDetailsForStarHealthRequest = (
  authToken,
  digitisationId
) => {
  const url = `${config.apiBaseUrl}/digitisations/star-user-policy-info?digitsationId=${digitisationId}`;
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(url, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
};

export function callTosaveDoctorLastReadTimeToToken(
  consultationId,
  lastReadTimetoken,
  authToken
) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .put(
      `${config.apiBaseUrl}/new-doctor/consultations/update-doctor-last-read?lrtt=${lastReadTimetoken}&c=${consultationId}`,
      null,
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return;
      } else {
        // handle the following error and internet connectivity error in catch block
        const err = new Error('Unsuccessfull');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      // check the type of error, if internet connectivity issue
      // save the errored out saveLastReadTimetoken requests in store,
      // then dispatch them when app gets reconnected
    });
}

export function fetchQuickReplySuggestions(
  searchKey,
  verticalId,
  sponsorId,
  authToken
) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(
      `${config.apiBaseUrl}/new-doctor/consultations/get-quick-reply?v=${verticalId}&s=${sponsorId}&search=${searchKey}`,
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody.data;
      } else {
        const err = new Error('Unsuccessful');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      console.error({ err });
      return [];
    });
}

export function getPolicyStructureOfUser(digitisationId, authToken) {

  const headers = {
    authorization: authToken,
  };
  const url = 
  
 `/digitisations/get-user-policy-details/${digitisationId}`;

  return axios
    .get(`${config.apiBaseUrl}${url}`, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody.data;
      } else {
        const err = new Error('Unsuccessful');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      console.error({ err });
      return [];
    });
}

export function initiateVoiceCallForDigitizationUser(authToken, digitisationId,sourceType) {
  const headers = {
    authorization: authToken,
  };
  const body={
    digitisationId,
    sourceType
  }
  return axios
    .post(
      `${config.apiBaseUrl}/digitisations/voice/call`,
        body,
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      console.log('respo', { res });
      if (responseBody.message === 'Call Initiated') {
        return responseBody;
      } else {    
        const err = {
          data:{errorMessage:responseBody.response}
        }
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchVoiceCallLogsForDigitizationUser(authToken, digitisationId) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(
      `${config.apiBaseUrl}/digitisations/voice/call-logs/${digitisationId}`,
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      console.log('respo', { res });
      if (responseBody.message === 'success') {
        return responseBody.data;
      } else {    
        const err = {
          data:{errorMessage:responseBody.response}
        }
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      return [];
    });
}

export function fetchCallBacksForConsult(authToken, consultationId) {
  const headers = {
    authorization: authToken,
  };

  return axios.get(`${config.apiBaseUrl}/opd/get-pending-callbacks/${consultationId}`, {headers})
    .then((res) => {
      const responseBody = res.data;
      if(responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = {
          data:{errorMessage:responseBody.response}
        }
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
};

export function fetchAllSuspiciousReqByUserId(authToken,userId) {
  const headers = {
    authorization: authToken,
  };
  const url = 
  
 `/digitisations/fetch-suspicious-requests/${userId}`;

  return axios
    .get(`${config.apiBaseUrl}${url}`, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      console.log({ responseBody });
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody.result;
      } else {
        const err = new Error('Unsuccessful');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      console.error({ err });
      return [];
    });
}
export function markDigitizationReqSuspicious(authToken,body) {
  const headers = {
    authorization: authToken,
  };
 
  return axios
    .post(
      `${config.apiBaseUrl}/digitisations/mark-digitization-user-suspicious`,
        body,
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {    
        const err = {
          data:{errorMessage:responseBody.response}
        }
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchQcCases(authToken,digitizationId,status,page,pageSize) {
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(
      `${config.apiBaseUrl}/digitisations/get/qc-cases?digitisationId=${digitizationId}&status=${status}&page=${page}&pageSize=${pageSize}`,
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {    
        const err = {
          data:{errorMessage:responseBody.response}
        }
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
};
export function fetchAllSuspiciousDigitizationReq(authToken) {

  const headers = {
    authorization: authToken,
  };
  const url = 
  
 `/digitisations/fetch-all-digitization-suspicious-users`;

  return axios
    .get(`${config.apiBaseUrl}${url}`, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      console.log({ responseBody });
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody.result;
      } else {
        const err = new Error('Unsuccessful');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      console.error({ err });
      return [];
    });
}

export function markUnSuspiciousByUserId(authToken,userId) {

  const headers = {
    authorization: authToken,
  };
  const url = `/digitisations/suspicious-digitisation/${userId}`;

  return axios
    .delete(`${config.apiBaseUrl}${url}`, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      console.log({ responseBody });
      if (responseBody.message.toLowerCase() === 'success') {
        return responseBody.result;
      } else {
        const err = new Error('Unsuccessful');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function markAcknowledgeById(authToken,requestId) {
  const body={requestId}
  const headers = {
    authorization: authToken,
  };
  return axios
    .post(
      `${config.apiBaseUrl}/digitisations/qc-acknowledge-by`,body,
      {
        headers
      }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {    
        const err = {
          data:{errorMessage:responseBody.response}
        }
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function updateStatusQc(authToken,body) {
  if (body?.qcRemark) {
    body.remark = body.qcRemark;
  }
  const headers = {
    authorization: authToken,
  };
  return axios
    .put(
      `${config.apiBaseUrl}/digitisations/update/qc-status`,
      body,
      {
        headers
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {    
        const err = {
          data:{errorMessage:responseBody.response}
        }
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
};

export const downloadQcCsv = (params,authToken) => {
  const urlQuery = new URLSearchParams(params).toString();
  const url = `${config.apiBaseUrl}/digitisations/qc-csv-download?${urlQuery}`;
  const headers = {
    authorization: authToken,
  };
  window.open(`${url}&auth=${headers.authorization.split(' ')[1]}`);
  return Promise.resolve();
};
      




export function fetchUserBmi(authToken, userId, relativeId) {
  const headers = {
    authorization: authToken,
  }

  const params = {
    userId, 
    relativeId,
  }

  console.log({headers, params}, 'fetchUserBmi');

  return axios.get(`${config.apiBaseUrl}/new-doctor/medical-profile/bmi`, {headers, params})
  .then((res) => {
    const responseBody = res.data;
    console.log(res, 'fetchUserBmi');
    if(responseBody.message === 'success') {
      return responseBody;
    } else {
      const err = new Error('Unsuccessful');
      err.data = responseBody;
      throw err;
    }
  })
  .catch((err) => {
    showError(err);
    throw err;
  });
}

export function updateUserBmi(authToken, userId, relativeId, height, weight, consultationId) {
  const headers = {
    authorization: authToken,
  }

  const body = {
    userId,
    relativeId,
    height: Number(height),
    weight: Number(weight),
    consultationId
  }

  console.log({headers, body}, 'updateUserBmi');

  return axios.put(`${config.apiBaseUrl}/new-doctor/medical-profile/bmi`, body, {headers})
  .then((res) => {
    const responseBody = res.data;
    console.log({res}, 'updateUserBmi');
    if(responseBody.message === 'success') {
      return responseBody;
    } else {
      const err = new Error('Unsuccessful');
      err.data = responseBody;
      throw err;
    }
  })
  .catch((err) => {
    showError(err);
    throw err;
  });
}

export function fetchOptions(authToken, options, searchKey, type) {
  const headers = {
    authorization: authToken,
  }
  
  const params = {};

  if(options === 'allergies') {
    params.allergies = true;
    params.searchKey = searchKey;
    params.type = type;
  }

  if(options === 'chronicAilment') {
    params.chronicAilments = true;
    params.searchKey = searchKey;
  }

  if(options === 'pastSurgery') {
    params.pastSurgeries = true;
    params.searchKey = searchKey;
  }

  if(options === 'familyHistory') {
    params.familyHistory = true;
    params.searchKey = searchKey;
  }

  console.log({headers, params}, 'fetchOptions');

  return axios.get(`${config.apiBaseUrl}/new-doctor/medical-profile/options`, {headers, params})
  .then((res) => {
    const responseBody = res.data;
    console.log(res, 'fetchOptions');
    if(responseBody.message === 'success') {
      return responseBody;
    } else {
      const err = new Error('Unsuccessful');
      err.data = responseBody;
      throw err;
    }
  })
  .catch((err) => {
    showError(err);
    throw err;
  });
}

export function saveOptions(authToken, options) {
  const headers = {
    authorization: authToken,
  }

  const body = {
    ...options,
  }

  console.log({headers, body}, 'saveOptions');

  return axios.post(`${config.apiBaseUrl}/new-doctor/medical-profile/options`, body, {headers})
  .then((res) => {
    const responseBody = res.data;
    console.log({res}, 'saveOptions');
    if(responseBody.message === 'success') {
      return responseBody;
    } else {
      const err = new Error('Unsuccessful');
      err.data = responseBody;
      throw err;
    }
  })
  .catch((err) => {
    showError(err);
    throw err;
  });
}

export function fetchMedicalHistory(authToken, userId, relativeId) {
  const headers = {
    authorization: authToken,
  }

  const params = {
    userId,
    relativeId: relativeId ? relativeId : null,
  }

  console.log({headers, params}, 'fetchMedicalHistory');

  return axios.get(`${config.apiBaseUrl}/new-doctor/medical-profile`, {headers, params})
  .then((res) => {
    const responseBody = res.data;
    console.log({res}, 'fetchMedicalHistory');
    if(responseBody.message === 'success') {
      return responseBody;
    } else {
      const err = new Error('Unsuccessful');
      err.data = responseBody;
      throw err;
    }
  })
  .catch((err) => {
    showError(err);
    throw err;
  });
}

export function saveAllergies(authToken, userId, relativeId, allergies, consultationId) {
  const headers = {
    authorization: authToken,
  }

  const body = {
    userId,
    relativeId: relativeId ? relativeId : null,
    ...allergies,
    consultationId,
  }

  console.log({headers, body}, 'saveAllergies');

  return axios.post(`${config.apiBaseUrl}/new-doctor/medical-profile/allergies`, body, {headers})
  .then((res) => {
    const responseBody = res.data;
    console.log({res}, 'saveAllergies');
    if(responseBody.message === 'success') {
      return responseBody;
    } else {
      const err = new Error('Unsuccessful');
      err.data = responseBody;
      throw err;
    }
  })
  .catch((err) => {
    showError(err);
    throw err;
  });
}

export function saveChronicAilments(authToken, userId, relativeId, chronicAilments, consultationId) {
  const headers = {
    authorization: authToken,
  }

  const body = {
    userId,
    relativeId: relativeId ? relativeId : null,
    ...chronicAilments,
    consultationId,
  }

  console.log({headers, body}, 'saveChronicAilments');

  return axios.post(`${config.apiBaseUrl}/new-doctor/medical-profile/chronic-ailments`, body, {headers})
  .then((res) => {
    const responseBody = res.data;
    console.log({res}, 'saveChronicAilments');
    if(responseBody.message === 'success') {
      return responseBody;
    } else {
      const err = new Error('Unsuccessful');
      err.data = responseBody;
      throw err;
    }
  })
  .catch((err) => {
    showError(err);
    throw err;
  });
}

export function savePastSurgeries(authToken, userId, relativeId, pastSurgeries, consultationId) {
  const headers = {
    authorization: authToken,
  }

  const body = {
    userId,
    relativeId: relativeId ? relativeId : null,
    ...pastSurgeries,
    consultationId,
  }

  console.log({headers, body}, 'savePastSurgeries');

  return axios.post(`${config.apiBaseUrl}/new-doctor/medical-profile/past-surgeries`, body, {headers})
  .then((res) => {
    const responseBody = res.data;
    console.log({res}, 'savePastSurgeries');
    if(responseBody.message === 'success') {
      return responseBody;
    } else {
      const err = new Error('Unsuccessful');
      err.data = responseBody; 
      throw err;
    }
  })
  .catch((err) => {
    showError(err);
    throw err;
  });
}

export function saveFamilyHistory(authToken, userId, relativeId, familyHistory, consultationId) {
  const headers = {
    authorization: authToken,
  }

  const body = {
    userId,
    relativeId: relativeId ? relativeId : null,
    ...familyHistory,
    consultationId,
  }

  console.log({headers, body}, 'saveFamilyHistory');

  return axios.post(`${config.apiBaseUrl}/new-doctor/medical-profile/family-history`, body, {headers})
  .then((res) => {
    const responseBody = res.data;
    console.log({res}, 'saveFamilyHistory');
    if(responseBody.message === 'success') {
      return responseBody;
    } else {
      const err = new Error('Unsuccessful');
      err.data = responseBody; 
      throw err;
    }
  })
  .catch((err) => {
    showError(err);
    throw err;
  });
}

export function deleteAllergies(authToken, userId, relativeId, type, allergy, consultationId) {
  const headers = {
    authorization: authToken,
  }

  const body = {
    userId,
    relativeId: relativeId ? relativeId : null,
    typeOfAllergy: type,
    allergy,
    consultationId,
  }

  console.log({headers, body}, 'deleteAllergies');

  return axios.delete(`${config.apiBaseUrl}/new-doctor/medical-profile/allergies`, {headers, data: body})
  .then((res) => {
    const responseBody = res.data;
    console.log({res}, 'deleteAllergies');
    if(responseBody.message === 'success') {
      return responseBody;
    } else {
      const err = new Error('Unsuccessful');
      err.data = responseBody; 
      throw err;
    }
  })
  .catch((err) => {
    showError(err);
    throw err;
  });
}

export function deleteChronicAilments(authToken, userId, relativeId, ailment, consultationId) {
  const headers = {
    authorization: authToken,
  }

  const body = {
    userId,
    relativeId: relativeId ? relativeId : null,
    chronicAilment: ailment,
    consultationId,
  }

  console.log({headers, body}, 'deleteChronicAilments');

  return axios.delete(`${config.apiBaseUrl}/new-doctor/medical-profile/chronic-ailments`, {headers, data: body})
  .then((res) => {
    const responseBody = res.data;
    console.log({res}, 'deleteChronicAilments');
    if(responseBody.message === 'success') {
      return responseBody;
    } else {
      const err = new Error('Unsuccessful');
      err.data = responseBody; 
      throw err;
    }
  })
  .catch((err) => {
    showError(err);
    throw err;
  });
}

export function deletePastSurgeries(authToken, userId, relativeId, surgery, consultationId) {
  const headers = {
    authorization: authToken,
  }

  const body = {
    userId,
    relativeId: relativeId ? relativeId : null,
    pastSurgery: surgery,
    consultationId,
  }

  console.log({headers, body}, 'deletePastSurgeries');

  return axios.delete(`${config.apiBaseUrl}/new-doctor/medical-profile/past-surgeries`, {headers, data: body})
  .then((res) => {
    const responseBody = res.data;
    console.log({res}, 'deletePastSurgeries');
    if(responseBody.message === 'success') {
      return responseBody;
    } else {
      const err = new Error('Unsuccessful');
      err.data = responseBody; 
      throw err;
    }
  })
  .catch((err) => {
    showError(err);
    throw err;
  });
}

export function deleteFamilyHistory(authToken, userId, relativeId, history, consultationId) {
  const headers = {
    authorization: authToken,
  }

  const body = {
    userId,
    relativeId: relativeId ? relativeId : null,
    familyHistory: history,
    consultationId,
  }

  console.log({headers, body}, 'deleteFamilyHistory');

  return axios.delete(`${config.apiBaseUrl}/new-doctor/medical-profile/family-history`, {headers, data: body})
  .then((res) => {
    const responseBody = res.data;
    console.log({res}, 'deleteFamilyHistory');
    if(responseBody.message === 'success') {
      return responseBody;
    } else {
      const err = new Error('Unsuccessful');
      err.data = responseBody; 
      throw err;
    }
  })
  .catch((err) => {
    showError(err);
    throw err;
  });
}

export function fetchPastTreatments(authToken, consultationId) {
  const headers = {
    authorization: authToken,
  }

  console.log({headers}, 'fetchPastTreatments');

  return axios.get(`${config.apiBaseUrl}/new-doctor/consultations/${consultationId}/pastTreatments`, {headers})
  .then((res) => {
    const responseBody = res.data;
    console.log({res}, 'fetchPastTreatments');
    if(responseBody.message === 'success') {
      return responseBody;
    } else {
      const err = new Error('Unsuccessful');
      err.data = responseBody;
      throw err;
    }
  })
  .catch((err) => {
    showError(err);
    throw err;
  });
}

export function fetchActiveConsultChatChannels(authToken, consultationId, userId, relativeId) {
  const headers = {
    authorization: authToken,
  }

  const params = {
    consultationId,
    relativeId
  }
  console.log(userId, relativeId);
  return axios.get(`${config.apiBaseUrl}/new-doctor/get-chat-channels/${userId}`, {headers, params})
    .then((res) => {
      const responseBody = res.data;
      console.log({res}, 'fetchActiveConsultChatChannels');
      if(responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Unsuccessful');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}