const config = {
  development: {
    pubnubPublishKey: 'pub-c-b8b14417-cff1-4e64-aa43-b41a777352cf',
    pubnubSubscribeKey: 'sub-c-42db9ed2-315c-11e6-9327-02ee2ddab7fe',
    apiBaseUrl: 'https://api.samuraijack.xyz/v3',
    // apiBaseUrl: 'http://localhost:5001',
    opentokApiKey: '45467122',
    pharmaServer: 'https://api.getvisitapp.net/absol',
    //pharmaServer: 'http://localhost:2020',
    starSponsorId: 568,
    covidSymptomId: 4811,
    psychologistVerticalId: 1,
    version: '125',
    googleMapsApiKey: 'AIzaSyCOh6iK7nfOvJrfpG3RJsNzyJoJtSl3OXc',
    dashboardVersion: 43,
  },
  // staging: {
  //   pubnubPublishKey: 'pub-c-b8b14417-cff1-4e64-aa43-b41a777352cf',
  //   pubnubSubscribeKey: 'sub-c-42db9ed2-315c-11e6-9327-02ee2ddab7fe',
  //   apiBaseUrl: 'https://api.getvisitapp.xyz/v3',
  //   opentokApiKey: '45467122',
  // },
  staging: {
    pubnubPublishKey: 'pub-c-b8b14417-cff1-4e64-aa43-b41a777352cf',
    pubnubSubscribeKey: 'sub-c-42db9ed2-315c-11e6-9327-02ee2ddab7fe',
    apiBaseUrl: 'https://api.samuraijack.xyz/v3',
    opentokApiKey: '45467122',
    pharmaServer: 'https://retail-stage.getvisitapp.net/absol',
    starSponsorId: 196,
    psychologistVerticalId: 1,
    version: '125',
    googleMapsApiKey: 'AIzaSyCOh6iK7nfOvJrfpG3RJsNzyJoJtSl3OXc',
    dashboardVersion: 43,
  },
  production: {
    pubnubPublishKey: 'pub-c-fb8ac7e3-75bf-4396-bf14-77215f8b9419',
    pubnubSubscribeKey: 'sub-c-78deef00-680f-11e5-a57f-0619f8945a4f',
    apiBaseUrl: 'https://api.getvisitapp.com/v3',
    opentokApiKey: '45440642',
    pharmaServer: 'https://api.getvisitapp.com/pharmacy',
    starSponsorId: 568,
    covidSymptomId: 3642,
    psychologistVerticalId: 1,
    version: '125',
    googleMapsApiKey: 'AIzaSyCOh6iK7nfOvJrfpG3RJsNzyJoJtSl3OXc',
    dashboardVersion: 43,
  },
};
export default {
  pubnubPublishKey: 'pub-c-fb8ac7e3-75bf-4396-bf14-77215f8b9419',
  pubnubSubscribeKey: 'sub-c-78deef00-680f-11e5-a57f-0619f8945a4f',
  apiBaseUrl: 'https://api.getvisitapp.com/v3',
  opentokApiKey: '45440642',
  pharmaServer: 'https://api.getvisitapp.com/pharmacy',
  starSponsorId: 568,
  covidSymptomId: 3642,
  psychologistVerticalId: 1,
  version: '125',
  googleMapsApiKey: 'AIzaSyCOh6iK7nfOvJrfpG3RJsNzyJoJtSl3OXc',
  dashboardVersion: 51,
} || config[process.env.NODE_ENV];